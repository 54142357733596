import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHepers/FormTextInput';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';
import MsoSelectBox from 'components/dropdowns/MsoSelectBox';

import {
  setSelectedMsoId,
  addRateCode
} from 'features/rateCodes/rateCodesSlice';

const CreateCode = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    success,
    error,
    selectedMsoId,
  } = useSelector((state) => state.rateCodes);


  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    mso_id: selectedMsoId || 0,
    code: '',
    code_type: '',
    name: '',
    value: '',
    quantity_locked: true,
  });

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = 'A Name is required';
    }

    if (!formData.code) {
      newErrors.code = 'A Code is required';
    }

    if (!formData.code_type) {
      newErrors.code_type = 'A Code Type is required';
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch(name) {
      case 'mso_id':
        dispatch(setSelectedMsoId(value));
        break;
      default:
        break;
    }

    if (name === 'mso_id' || name === 'company_id') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      const newData = formData;
      newData[name] = value;
      setFormData(newData);
    }
  }

  const handleCheckChanges = (e) => {
    const { name, checked } = e.target;
    const newData = formData;
    newData[name] = checked;
    setFormData(newData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});

    if (validateForm()) {
      dispatch(addRateCode({formData, navigate}))
    }
  };

  const handleBack = () => {
    navigate('/rate_codes');
  };

  return (

    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={'Create RateCode'}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`RateCode "${formData.name}" Created`}
        success={success}
        error={error}
      />

      <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">

        <MsoSelectBox
          label={'MSO'}
          name={'mso_id'}
          value={formData.mso_id}
          onChange={handleChange}
          useTarget={false}
        />

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Name'}
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter a Name"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Code'}
            name="code"
            value={formData.code}
            onChange={handleChange}
            placeholder="Enter a Code"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Code Type'}
            name="code_type"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter a Code Type"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Value'}
            name="value"
            value={formData.value}
            onChange={handleChange}
            placeholder="Enter a Value"
            formErrors={formErrors}
          />
        </div>

        <div className="flex items-center mb-4">
          <input
            id="quantity_locked"
            name="quantity_locked"
            type="checkbox"
            defaultChecked={formData.quantity_locked}
            onChange={handleCheckChanges}
            className="border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" />

          <label htmlFor="quantity_locked" className="ms-2 text-sm font-medium text-gray-700">Quantity Locked</label>
        </div>

        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create RateCode
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCode;
