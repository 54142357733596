import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUsers,
  clearUsers,
  setPageSize,
  setSuccess,
  setError,
  setSelectedMsoId
} from '../../features/users/usersSlice';
import { Link, useNavigate } from 'react-router-dom';
import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';

import MsoSelectBox from '../dropdowns/MsoSelectBox';
import HeaderTitleBar from '../HeaderTitleBar';
import { fetchUserEnums } from "features/users/usersSlice";
import FormSelectBox from 'components/formHepers/FormSelectBox';

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user_types, roles, statuses } = useSelector((state) => state.users);
  const [user_type, setUserType] = useState(0)
  const [status, setStatus] = useState(0)
  const [role, setRole] = useState(0)

  const fetchEnumData = useCallback(() => {
    dispatch(fetchUserEnums());
  }, [dispatch]);

  useEffect(() => {
    fetchEnumData();
  }, [fetchEnumData]);

  const { data,
    loading,
    pageCount,
    totalItems,
    pageSize,
    selectedMsoId,
  } = useSelector((state) => state.users);

  const handleSelectedMsoId = (value) => {
    dispatch(setSelectedMsoId(value));
  }

  setSuccess(false)
  setError(false)

  const ViewUser = ({ field, row }) => {
    return <Link
      to={`/users/${row.original.id}`}
      onClick={() => { navigate( `/users/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const MailUser = ({ field, row }) => {
    return <Link
      to={`mailto:${row.original.email}`}
      className="font-medium text-blue-600 dark:text-blue-500 flex items-center">
        <svg
          className="h-6 w-6 text-blue-500 mr-1"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round">

          <path stroke="none" d="M0 0h24v24H0z"/>
          <rect x="3" y="5" width="18" height="14" rx="2" />
          <polyline points="3 7 12 13 21 7" />
        </svg>

        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id' },
    {
      header: 'First Name',
      accessorKey: 'first_name',
      cell: ({ getValue, row }) => <ViewUser field={'first_name'} value={getValue()} row={row} />
    },
    {
      header: 'Last Name',
      accessorKey: 'last_name',
      cell: ({ getValue, row }) => <ViewUser field={'last_name'} value={getValue()} row={row} />
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: ({ getValue, row }) => <MailUser field={'email'} value={getValue()} row={row} />
    },
    { header: 'Status', accessorKey: 'status' },
    { header: 'Role', accessorKey: 'role' },
    { header: 'Type', accessorKey: 'user_type' },
    { header: 'Phone Number', accessorKey: 'phone_number' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchUsersData = useCallback(() => {
    if (typeof(selectedMsoId) === 'undefined') {
      return;
    }

    dispatch(fetchUsers({
      msoId: selectedMsoId,
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      sorting,
      user_type,
      role,
      status,
      navigate
    }));
  }, [ dispatch,
    selectedMsoId,
    pageIndex,
    pageSize,
    searchQuery,
    sorting,
    user_type,
    role,
    status,
    navigate
  ]);

  useEffect(() => {
    if (parseInt(selectedMsoId) === 0) {
      dispatch(clearUsers());
    } else {
      fetchUsersData();
    }
  }, [dispatch, selectedMsoId, fetchUsersData]);


  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    setPageIndex(0);
  };

  const handleCreateClick = () => {
    navigate('/users/create')
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch(name) {
      case 'user_type':
        setUserType(value);
        break;
      case 'role':
        setRole(value);
        break;
      case 'status':
        setStatus(value)
        break;
    }
  };

  const ToolBox = ({ user_type, role, status, onChange }) => {
    return (
      <div className="grid grid-cols-3 gap-1 m-0">
        <FormSelectBox
          name="user_type"
          defaultValue={user_type}
          valueField={'id'}
          keyField={'name'}
          zeroOption={'Select all User Types'}
          onChange={onChange}
          data={user_types}
        />
        <FormSelectBox
          name="role"
          defaultValue={role}
          valueField={'id'}
          keyField={'name'}
          zeroOption={'Select all Roles'}
          onChange={onChange}
          data={roles}
        />
        <FormSelectBox
          name="status"
          defaultValue={status}
          valueField={'id'}
          keyField={'name'}
          zeroOption={'Select all Statuses'}
          onChange={onChange}
          data={statuses}
        />
      </div>
    );
  };


  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Users'
          showBtn={true}
          btnTitle='Create User'
          btnClick={handleCreateClick}
        />
        <div className="mt-4">
          <MsoSelectBox onChange={handleSelectedMsoId} value={selectedMsoId} />
        </div>
      </div>
      <TosDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
        toolbox={<ToolBox
          user_type={user_type}
          role={role}
          status={status}
          onChange={handleChange}
        />}
      />
    </>
  )
};

export default Users;