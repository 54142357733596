import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHepers/FormTextInput';
import FormPasswordInput from 'components/formHepers/FormPasswordInput';
import FormStatesSelectBox from 'components/formHepers/FormStatesSelectBox';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';
import FormSelectBox from 'components/formHepers/FormSelectBox';
import { fetchUserEnums } from "features/users/usersSlice";
import MsoSelectBox from 'components/dropdowns/MsoSelectBox';
import CompanySelectBox from 'components/dropdowns/CompanySelectBox';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';

import { addUser } from 'features/users/usersSlice';
import 'tailwindcss/tailwind.css';

const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { success, error } = useSelector((state) => state.users);
  const { user_types, roles, statuses } = useSelector((state) => state.users);

  const fetchEnumData = useCallback(() => {
    dispatch(fetchUserEnums());
  }, [dispatch]);

  useEffect(() => {
    fetchEnumData();
  }, [fetchEnumData]);


  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: 0,
    zip: '',
    user_type: 0,
    role: 0,
    status: 0,
    mso_id: 0,
    company_id: 0,
    area_id: 0,
    password: '',
    password_confirmation: ''
  });

  const validatePasswords = (errors) => {
    if (formData.password.length < 8) {
      errors.password = 'Password must be at least 8 characters, numbers, and/or special characters'
      formData.password = '';
      return errors;
    }

    if (formData.password !== formData.password_confirmation) {
      errors.password_confirmation = 'Confirmation password does not match password'
      formData.password_confirmation = '';
      return errors;
    }

    return errors;
  }

  const validateForm = () => {
    let newErrors = {};

    if (!formData.first_name) {
      newErrors.first_name = 'First Name is required';
    }

    if (!formData.last_name) {
      newErrors.last_name = 'Last Name is required';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    }

    newErrors = validatePasswords(newErrors);
    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mso_id' || name === 'company_id') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      const newData = formData;
      if (name === 'user_type' || name === 'status' || name === 'role') {
        if (value === 0) {
          value = null;
        }
      }

      newData[name] = value;
      setFormData(newData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});

    if (validateForm()) {
      dispatch(addUser(formData))
    }
  };

  const handleBack = () => {
    navigate('/users');
  };

  return (

    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={'Create User'}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`User "${formData.first_name} ${formData.last_name}" Created`}
        success={success}
        error={error}
      />

      <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">

        <MsoSelectBox
          label={'MSO'}
          name={'mso_id'}
          value={formData.mso_id}
          onChange={handleChange}
          useTarget={false}
        />

        <CompanySelectBox
          label={'Company'}
          name={'company_id'}
          value={formData.company_id}
          onChange={handleChange}
          useTarget={false}
          msoId={formData.mso_id}
        />

        <AreaSelectBox
          label={'Area'}
          name={'area_id'}
          value={formData.area_id}
          onChange={handleChange}
          useTarget={false}
          msoId={formData.mso_id}
          companyId={formData.company_id}
        />

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'First Name'}
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="Enter First Name"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Last Name'}
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Enter Last Name"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Email'}
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter Email"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Phone Number'}
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            placeholder="Enter Phone Number"
            formErrors={formErrors}
          />
        </div>

        <FormTextInput
          label={'Address 1'}
          name="address_1"
          value={formData.address_1}
          onChange={handleChange}
          placeholder="Enter Address"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Address (continued)'}
          name="address_2"
          value={formData.address_2}
          onChange={handleChange}
          placeholder="Enter Address (continued)"
          formErrors={formErrors}
        />

        <div className="grid justift-items-start grid-cols-3 gap-8">
          <FormTextInput
            label={'City'}
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter City"
            formErrors={formErrors}
          />

          <FormStatesSelectBox
            state_id={formData.state_id}
            required={false}
            formErrors={formErrors}
            onChange={handleChange}
          />

          <FormTextInput
            label={'Zip'}
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            placeholder="Enter Zip Code"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-3 gap-8">
          <FormSelectBox
            label={'User Type'}
            name="user_type"
            defaultValue={formData.user_type}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a User Type'}
            onChange={handleChange}
            formErrors={formErrors}
            data={user_types}
            useDefault={true}
          />

          <FormSelectBox
            label={'Role'}
            name="role"
            defaultValue={formData.role}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Role'}
            onChange={handleChange}
            formErrors={formErrors}
            data={roles}
            useDefault={true}
          />

          <FormSelectBox
            label={'Status'}
            name="status"
            defaultValue={formData.status}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Status'}
            onChange={handleChange}
            formErrors={formErrors}
            data={statuses}
            useDefault={true}
          />
        </div>
        <div className="grid justift-items-start grid-cols-2 gap-8 pt-10">
          <FormPasswordInput
            label={'Password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter a Password"
            formErrors={formErrors}
          />

          <FormPasswordInput
            label={'Password Confirmation'}
            name="password_confirmation"
            value={formData.password_confirmation}
            onChange={handleChange}
            placeholder="Confirm your Password"
            formErrors={formErrors}
          />
        </div>
        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create User
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;
