import React, { useEffect, useCallback, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { fetchAllAreas } from 'features/areas/areasSlice';
import FormSelectBox from 'components/formHepers/FormSelectBox';
import { viewJobs } from 'features/billing/billingSlice';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormDatePicker from 'components/formHepers/FormDatePicker';
import { formatDateToMMDDYYYY } from 'utils/TextTools';
import JobNumberLink from './components/JobNumberLink';
import { formatEnumKey } from 'utils/TextTools';

const ViewJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  // const [selectedStartDate, setSelectedStartDate] = useState(new Date('2024-05-07'));
  // const [selectedEndDate, setSelectedEndDate] = useState(new Date('2024-05-28'));
  const [selectedAreaId, setSelectedAreaId] = useState(1);

  const { data: areas } = useSelector((state) => state.areas);
  const { data: jobs } = useSelector((state) => state.billing);

  const fetchAreasData = useCallback(() => {
    dispatch(fetchAllAreas({ page: 1, per: 99999, navigate }));
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchAreasData(navigate);
  }, [fetchAreasData, navigate]);

  const handleStartChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleEndChange = (date) => {
    setSelectedEndDate(date);
  }

  const handleAreaChange = (event) => {
    setSelectedAreaId(event.target.value)
  }

  const handleViewJobs = () => {
    dispatch(viewJobs({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      areaId: selectedAreaId
    }));
  }

  const handleDelete = () => {

  }

  const filteredJobs = (tech_id) => {
    return jobs.jobs.filter(t => t.tech_id === tech_id)
  }

  const filterEnumById = (enum_list, id) => {
    let value = Object.keys(enum_list).find(k => enum_list[k] === id);
    return formatEnumKey(value);
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
         <HeaderTitleBar
          title='View Jobs'
          showBtn={false}
        />

        <div className="w-1/4 mt-6 items-left">
          <FormSelectBox
            label={'Area'}
            name="areabox"
            defaultValue={selectedAreaId}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select an Area'}
            onChange={handleAreaChange}
            data={areas}
            horizontal={true}
          />

          <FormDatePicker
            label={'Start Date'}
            name="start_date"
            onChange={handleStartChange}
            placeholder="Enter a Start Date"
            value={selectedStartDate}
            horizontal={true}
          />


          <FormDatePicker
            label={'End Date'}
            name="end_date"
            onChange={handleEndChange}
            placeholder="Enter an End Date"
            value={selectedEndDate}
            horizontal={true}
          />

          <div className="flex md:items-center mb-6">
            <button onClick={handleViewJobs} className="bg-blue-600 text-white px-4 p-1">View Jobs</button>
          </div>

        </div>
      </div>

      {(jobs && jobs?.techs && jobs.techs.length > 0) && (
        <table className="min-w-full divide-y divide-gray-200 bg-white border-gray-200 border-solid border-b-1">
          <tbody>
            {jobs.techs.map((tech, index) => (
              <tr key={index}>
                <td>
                  <table className='w-full'>
                    <tbody>
                      <tr>
                        <td colSpan="6" className='p-2'>
                          {tech.tech_id}::{tech.techname}
                        </td>
                      </tr>
                      <tr className='bg-gray-500 font-normal'>
                        <td className='p-2 font-semibold text-sm text-white'>Date</td>
                        <td className='p-2 font-semibold text-sm text-white'>Job</td>
                        <td className='p-2 font-semibold text-sm text-white'>Dispatch Status</td>
                        <td className='p-2 font-semibold text-sm text-white'>Bill Status</td>
                        <td className='p-2 font-semibold text-sm text-white'>Customer Address</td>
                        <td className='p-2 font-semibold text-sm text-white text-center'>Options</td>
                      </tr>
                      {filteredJobs(tech.tech_id).map((job, jobidx) => (
                          <tr key={`job-${jobidx}`}>
                            <td className='p-2 text-xs'>{formatDateToMMDDYYYY(job.job_date)}</td>
                            <td className='p-2 text-xs'>
                              <JobNumberLink job_id={job.id} value={job.job_number} />
                            </td>
                            <td className='p-2 text-xs'>{filterEnumById(jobs.statuses, job.status)}</td>
                            <td className='p-2 text-xs'>{filterEnumById(jobs.billing_statuses, job.billing_status)}</td>
                            <td className='p-2 text-xs'>{job.address}</td>
                            <td className='p-2 text-xs text-center'>
                              <button
                                onClick={() => handleDelete(job.id)}
                                className="text-xs text-white font-bold"
                              >
                                <svg className="h-4 w-4 text-red-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z"/>
                                  <line x1="4" y1="7" x2="20" y2="7" />
                                  <line x1="10" y1="11" x2="10" y2="17" />
                                  <line x1="14" y1="11" x2="14" y2="17" />
                                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                </svg>
                              </button>

                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ViewJobs;