import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

export const fetchJobRouteNotes = createAsyncThunk(
  'jobRouteNotes/fetchJobRouteNotes',
  async ({ jobRouteId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_route_notes`;
      const response = await fetchWithAuth(url);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addJobRouteNote = createAsyncThunk(
  'jobRouteNotes/addJobRouteNote',
  async ({ jobRouteId, noteType, noteContent }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_route_notes`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          job_route_note: {
            note_type: noteType,
            note: noteContent
          }
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editJobRouteNote = createAsyncThunk(
  'jobRouteNotes/editJobRouteNote',
  async ({ jobRouteId, noteId, noteContent }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_route_notes/${noteId}`;
      const response = await fetchWithAuth(url, {
        method: 'PUT',
        body: JSON.stringify({
          job_route_note: { note: noteContent }
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteJobRouteNote = createAsyncThunk(
  'jobRouteNotes/deleteJobRouteNote',
  async ({ jobRouteId, noteId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes/${jobRouteId}/job_route_notes/${noteId}`;
      await fetchWithAuth(url, {
        method: 'DELETE'
      });

      return noteId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const jobRouteNotesSlice = createSlice({
  name: 'jobRouteNotes',
  initialState: {
    notes: [],
    loading: false,
    error: null,
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearNotes: (state) => {
      state.notes = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobRouteNotes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchJobRouteNotes.fulfilled, (state, action) => {
      state.loading = false;
      state.notes = action.payload;
    });
    builder.addCase(fetchJobRouteNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(addJobRouteNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addJobRouteNote.fulfilled, (state, action) => {
      state.loading = false;
      state.notes.push(action.payload);
    });
    builder.addCase(addJobRouteNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(editJobRouteNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editJobRouteNote.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.notes.findIndex(note => note.id === action.payload.id);
      if (index !== -1) {
        state.notes[index] = action.payload;
      }
    });
    builder.addCase(editJobRouteNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteJobRouteNote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteJobRouteNote.fulfilled, (state, action) => {
      state.loading = false;
      state.notes = state.notes.filter((note) => note.id !== action.payload);
    })
    builder.addCase(deleteJobRouteNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { setLoading, setError, clearNotes } = jobRouteNotesSlice.actions;
export default jobRouteNotesSlice.reducer;