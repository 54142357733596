import React, { useEffect, useCallback, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { fetchAllAreas } from 'features/areas/areasSlice';
import { generateExports } from 'features/billing/billingSlice';
import FormDatePicker from 'components/formHepers/FormDatePicker';
import MsoSelectBox from 'components/dropdowns/MsoSelectBox';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { formatDateToMMDDYYYY } from 'utils/TextTools';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';

const GenerateInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedMsoId, setSelectedMsoId] = useState(0);

  const { success, error } = useSelector((state) => state.billing)

  const fetchAreasData = useCallback(() => {
    if (parseInt(selectedMsoId) === 0 || typeof(selectedMsoId) === 'undefined') {
      return;
    }

    dispatch(fetchAllAreas({ page: 1, per: 99999, navigate }));
  }, [dispatch, selectedMsoId, navigate]);

  useEffect(() => {
    fetchAreasData(navigate);
  }, [fetchAreasData, navigate]);


  const handleStartChange = (date) => {
    setSelectedStartDate(date);
  }

  const handleEndChange = (date) => {
    setSelectedEndDate(date);
  }

  const handleAreaChange = (e) => {
    const { value } = e.target;
    setSelectedAreaId(value);
  }

  const handleSelectedMsoId = (value) => {
    setSelectedMsoId(value);
  }

  const handleGenerate = (e) => {
    e.preventDefault();

    if (parseInt(selectedAreaId) === 0 || typeof(selectedAreaId) === 'undefined') {
      return;
    }

    dispatch(generateExports({
      startDate: formatDateToMMDDYYYY(selectedStartDate),
      endDate: formatDateToMMDDYYYY(selectedEndDate),
      areaId: selectedAreaId
    }));
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
       <HeaderTitleBar
          title='Generate Invoice'
          showBtn={false}
        />
      {error}
      {success}
        <FormSubmissionResult
          successText={`Export Created`}
          success={success}
          error={error}
        />

        <form onSubmit={handleGenerate} className="space-y-0 mt-6 w-1/2">
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <MsoSelectBox
              label={'MSO'}
              onChange={handleSelectedMsoId}
              value={selectedMsoId}
              horizontal={true}
            />

            <AreaSelectBox
              label={'Area'}
              name={'area_id'}
              value={selectedAreaId}
              onChange={handleAreaChange}
              useTarget={false}
              msoId={selectedMsoId}
              horizontal={true}
            />
          </div>
          <div className="grid justift-items-start grid-cols-2 gap-4">
            <FormDatePicker
              label={'Start Date'}
              name="start_date"
              onChange={handleStartChange}
              placeholder="Enter a Start Date"
              value={selectedStartDate}
              horizontal={true}
            />

            <FormDatePicker
              label={'End Date'}
              name="end_date"
              onChange={handleEndChange}
              placeholder="Enter an End Date"
              value={selectedEndDate}
              horizontal={true}
            />

          </div>
          <div className="flex md:items-center mb-6">
            <button className="bg-blue-600 text-white px-4 p-1">Generate Invoice</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GenerateInvoice;