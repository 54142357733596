import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithAuth } from '../../utils/ApiTools'

const initialState = {
  data: [],
  areaData: null,
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  selectedMsoId: localStorage.getItem('msoId') || 0
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchAreas = createAsyncThunk(
    'areas/all',
    async ({
      msoId,
      page = 1,
      per = 25,
      search = '',
      sorting = [],
      navigate }, { rejectWithValue }) => {

  let sortBy = '';
  let direction = '';

  const sortField = sorting.find(s => s.selected);

  sortBy = sortField ? sortField.id : 'id';
  direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

  try {
    const response = await fetchWithAuth(
      `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/areas?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`
    , navigate);

    return response;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const fetchAllAreas = createAsyncThunk(
  'jobRateCodes/fetchAllAreas',
  async ({msoId, per, page, navigate }, { rejectWithValue }) => {
    const selectedMsoId = msoId || localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${selectedMsoId}/areas?per=9999`
      , {}, navigate);

      return response.data;
    } catch (error) {
      console.log("ERROR", error)
      return rejectWithValue(error.message);
    }
  }
);

export const fetchArea = createAsyncThunk(
  'areas/fetchArea',
  async (areaId, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/areas/${areaId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


export const addArea = createAsyncThunk(
  'areas/addArea',
  async ({ formData, navigate }, { rejectWithValue }) => {
    console.log('formData', formData)
    const {
      name,
      mso_id,
      phone_number,
      address_1,
      address_2,
      city,
      zip,
      state_id
    } = formData;

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${mso_id}/areas`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({ area: {
          name,
          mso_id,
          phone_number,
          address_1,
          address_2,
          city,
          zip,
          state_id
        }}),
      }, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editArea = createAsyncThunk(
  'area/edit',
  async ({ areaId, formData, navigate }, { rejectWithValue }) => {
    const msoId = formData.mso_id;

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/areas/${areaId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ area: formData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


const areasSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    clearAreas: () => {
      return initialState;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedMsoId: (state, action) => {
      state.selectedMsoId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAreas.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchAreas.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    })
    .addCase(fetchAreas.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.pageCount = 0;
      state.totalItems = 0;
      state.pageSize = 25;
      state.error = action.error.message;
    })

    .addCase(fetchAllAreas.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchAllAreas.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    })
    .addCase(fetchAllAreas.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })
    .addCase(fetchArea.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchArea.fulfilled, (state, action) => {
      state.loading = false;
      state.areaData = action.payload;
    })
    .addCase(fetchArea.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(addArea.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    })
    .addCase(addArea.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.data.push(action.payload)
    })
    .addCase(addArea.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(editArea.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editArea.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.areaData = action.payload;
    })
    .addCase(editArea.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    });
}
});


export const {
  setSelectedMsoId,
  clearAreas,
  setPageSize,
  setSuccess,
  setError
} = areasSlice.actions;
export default areasSlice.reducer;