import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

import { useSelector } from 'react-redux';
import { selectAuthToken } from './features/auth/authSlice';

function App() {
  const token = useSelector(selectAuthToken);

  return (
    <Provider store={store}>
      <Router>
        {token ? ( <Dashboard /> ) : ( <Login /> )}
      </Router>
    </Provider>
  );
}

export default App;