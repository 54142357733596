import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  data: [],
  lockedJob: null,
  loading: false,
  error: null,
};

export const fetchLockedJobs = createAsyncThunk(
  'lockedJobs/fetchAll',
  async ({navigate}, {rejectWithValue}) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/locked_jobs`;
      const response = await fetchWithAuth(url, {}, navigate)

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createLockedJob = createAsyncThunk(
  'lockedJobs/create',
  async ({lockedJobData, navigate}, {rejectWithValue}) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/locked_jobs`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          locked_job: lockedJobData
        }, navigate),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue({ error: error.message });
    }
  }
);

export const toggleLockedJobs = createAsyncThunk(
  'lockedJobs/toggleLockedJobs',
  async ({lockedJobId, navigate}, {rejectWithValue}) => {
    const msoId = localStorage.getItem('msoId');
    const companyId = localStorage.getItem('companyId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/locked_jobs/${lockedJobId}/toggle_lock`;
      const response = await fetchWithAuth(url, { method: 'PATCH', navigate });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const lockedJobsSlice = createSlice({
  name: 'lockedJobs',
  initialState,
  reducers: {
    clearLockedJob: (state) => {
      state.lockedJob = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLockedJobs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLockedJobs.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchLockedJobs.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error.message;
      })
      .addCase(createLockedJob.pending, (state) => {
        state.loading = true;
      })
      .addCase(createLockedJob.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(createLockedJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(toggleLockedJobs.pending, (state) => {
        state.loading = true;
      })
      .addCase(toggleLockedJobs.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.data.findIndex(job => job.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(toggleLockedJobs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearLockedJob } = lockedJobsSlice.actions;

export default lockedJobsSlice.reducer;
