import React from 'react';
import { useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table';

import 'tailwindcss/tailwind.css';

const SearchJobsDataTable = ({
  columns,
  data = [],
  pageCount = 0,
  totalItems = 0,
  pageIndex = 0,
  pageSize = 10,
  sorting = [],
  onPageChange,
  onPageSizeChange,
  onSortingChange,
  onSearchChange,
  loading = false
}) => {

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination: { pageIndex, pageSize },
      sorting
    },
    onPaginationChange: (updater) => {
      if (typeof updater === 'function') {
        const newState = updater({ pageIndex, pageSize });
        onPageChange(newState.pageIndex ?? pageIndex, newState.pageSize ?? pageSize);
      } else {
        onPageChange(updater.pageIndex ?? pageIndex, updater.pageSize ?? pageSize);
      }
    },
    onSortingChange: (newSorting) => {
      onSortingChange(newSorting);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    pageCount,
  });

  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => onSearchChange(e.target.value)}
          className="p-2 border border-gray-300 rounded"
        />
      </div>

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isSorted = table.getState().sorting.find(sort => sort.id === header.id);
                const isSortedDesc = isSorted ? isSorted.desc : false;

                return (
                  <th
                    key={header.id}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => {
                      const newSorting = [...sorting];
                      newSorting.forEach(s => s.selected = false )

                      const existing = newSorting.find(sort => sort.id === header.id);
                      if (existing) {
                        existing.desc = !existing.desc;
                        existing.selected = true;
                      } else {
                        newSorting.push({ id: header.id, desc: false, selected: true });
                      }

                      onSortingChange(newSorting);
                    }}
                  >
                    <div className="flex items-center">
                      {header.column.columnDef.header}
                      {isSorted ? (
                        isSortedDesc ? (
                          <span className="ml-2">🔽</span>
                        ) : (
                          <span className="ml-2">🔼</span>
                        )
                      ) : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {loading ? (
            <tr>
              <td colSpan={columns.length} className="text-center p-4">
                Loading...
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => {

                  const columnDef = cell.column.columnDef;
                  const FieldComponent = columnDef.fieldComponent;

                  return (
                    <td
                      key={cell.id}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {FieldComponent ? (
                        <FieldComponent job_id={row.getVisibleCells()[0].getValue()} value={cell.getValue()} />
                      ) : (
                        cell.getValue()
                      )}
                    </td>
                  )
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>

      <div className="py-3 flex items-center justify-between">
        <button
          onClick={() => onPageChange(pageIndex - 1, pageSize)}
          disabled={!table.getCanPreviousPage()}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {pageIndex + 1} of {table.getPageCount()}
        </span>
        <button
          onClick={() => onPageChange(pageIndex + 1, pageSize)}
          disabled={!table.getCanNextPage()}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md disabled:opacity-50"
        >
          Next
        </button>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className="ml-4 px-2 py-1 border rounded"
        >
          {[10, 25, 50].map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SearchJobsDataTable;