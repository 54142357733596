import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';

import HeaderTitleBar from '../HeaderTitleBar';
import MsoSelectBox from '../dropdowns/MsoSelectBox';
import {
  clearRateCodes,
  setSelectedMsoId,
  setPageSize,
  fetchMsoRateCodes
} from 'features/rateCodes/rateCodesSlice';

const Codes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ViewCode = ({ field, row }) => {
    return <Link
      to={`/rate_codes/${row.original.id}`}
      onClick={() => { navigate( `/rate_codes/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id' },
    {
      header: 'Name',
      accessorKey: 'name',
      cell: ({ getValue, row }) => <ViewCode field={'name'} value={getValue()} row={row} />
    },
    { header: 'Code Type', accessorKey: 'code_type' },
    { header: 'Code', accessorKey: 'code' },
    { header: 'Value', accessorKey: 'value' },
    { header: 'Qty Locked', accessorKey: 'quantity_locked' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const { data,
    loading,
    selectedMsoId,
    pageCount,
    totalItems,
    pageSize
  } = useSelector((state) => state.rateCodes);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchRateCodesData = useCallback(() => {
    if (typeof(selectedMsoId) == 'undefined') {
      return;
    }

    dispatch(fetchMsoRateCodes({
      msoId: selectedMsoId,
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      sorting,
      navigate
    }));
  }, [ dispatch, selectedMsoId, pageIndex, pageSize, searchQuery, sorting, navigate ]);

    useEffect(() => {
      if (parseInt(selectedMsoId) === 0) {
        dispatch(clearRateCodes());
      } else {
        fetchRateCodesData();
      }
    }, [dispatch, selectedMsoId, fetchRateCodesData]);


    const handleSelectedMsoId = (value) => {
      localStorage.setItem('msoId', value)
      dispatch(setSelectedMsoId(value));
    }

    const handlePageChange = (newPageIndex, newPageSize) => {
      setPageIndex(newPageIndex);
      if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
    };

    const handleSortingChange = (newSorting) => {
      setSorting(newSorting);
      setSortOverride(!sortOverride);
    };

    const handleSearchChange = (newSearchQuery) => {
      setSearchQuery(newSearchQuery);
      setPageIndex(0);
    };

    const handleCreateClick = () => {
      navigate('/rate_codes/create')
    }

    return (
      <>
        <div className="p-4 mb-6 bg-white shadow rounded-lg">
          <HeaderTitleBar
            title='Rate Codes'
            showBtn={true}
            btnTitle='Create Code'
            btnClick={handleCreateClick}
          />
          <div className="mt-4">
            <MsoSelectBox onChange={handleSelectedMsoId} value={selectedMsoId} />
          </div>
        </div>

        <TosDataTable
          columns={columns}
          data={data}
          pageCount={pageCount}
          totalItems={totalItems}
          pageIndex={pageIndex}
          pageSize={pageSize}
          sorting={sorting}
          loading={loading}
          onPageChange={handlePageChange}
          onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
          onSortingChange={handleSortingChange}
          onSearchChange={handleSearchChange}
        />
      </>
    );
  };


export default Codes;