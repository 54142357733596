import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SearchJobsDataTable from './components/SearchJobsDataTable';
import 'tailwindcss/tailwind.css';

import { fetchMsos } from '../../features/msos/msosSlice';
import { fetchCompanies, clearCompanies } from '../../features/companies/companiesSlice';
import JobNumberLink from './components/JobNumberLink';
import SelectBox from '../fields/SelectBox';

import {
  fetchJobRoutes,
  clearJobRoutes,
  setPageSize,
  setSelectedMsoId,
  setSelectedCompanyId,
  setSearchQuery
} from '../../features/jobRoutes/jobRouteFilterSlice';

const Jobroutes = () => {
  const navigate = useNavigate();

  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id' },
    { header: 'Job Number', accessorKey: 'job_number', fieldComponent: JobNumberLink },
    { header: 'Technician', accessorKey: 'user.full_name' },
    { header: 'Status', accessorKey: 'status' },
    { header: 'Area', accessorKey: 'area.name' },
    { header: 'Tech ID', accessorKey: 'tech_id' },
    { header: 'Account Number', accessorKey: 'account_number' },
    { header: 'Job Type', accessorKey: 'job_type' },
    { header: 'Job Date', accessorKey: 'job_date' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const dispatch = useDispatch();

  const {
    data,
    loading,
    pageCount,
    totalItems,
    pageSize,
    selectedMsoId,
    selectedCompanyId,
    selectedAreaId,
    selectedUserId,
    searchQuery
  } = useSelector((state) => state.jobRouteFilter);

  const { data: msosData } = useSelector((state) => state.msos);
  const { data: companiesData } = useSelector((state) => state.companies);
  const [pageIndex, setPageIndex] = useState(0);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999, navigate }));
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchMsosData(navigate);
  }, [fetchMsosData, navigate]);

  const fetchCompaniesData = useCallback(() => {
    dispatch(fetchCompanies({ msoId: selectedMsoId, page: 1, per: 9999, navigate }));
  }, [ dispatch, selectedMsoId, navigate]);

  useEffect(() => {
    if (selectedMsoId === 0) {
      dispatch(clearCompanies());
    } else {
      fetchCompaniesData(selectedMsoId);
    }
  }, [ selectedMsoId, fetchCompaniesData, dispatch ])

  const fetchJobRoutesData = useCallback(() => {
    if (selectedMsoId && selectedCompanyId) {
      dispatch(fetchJobRoutes({
        msoId: selectedMsoId,
        companyId: selectedCompanyId,
        areaId: selectedAreaId,
        userId: selectedUserId,
        page: pageIndex + 1,
        per: pageSize,
        search: searchQuery,
        sorting
      }));
    }
  }, [dispatch, selectedMsoId, selectedCompanyId, selectedAreaId, selectedUserId, pageIndex, pageSize, searchQuery, sorting]);

  useEffect(() => {
    if (!selectedCompanyId) return;

    if (!selectedMsoId || !selectedCompanyId) {
      dispatch(clearJobRoutes());
    } else {
      fetchJobRoutesData();
    }
  }, [selectedMsoId, selectedCompanyId, fetchJobRoutesData, dispatch]);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    dispatch(setSearchQuery(newSearchQuery));
    setPageIndex(0);
  };

  const handleSelectedMsoId = (value) => {
    dispatch(setSelectedMsoId(value));
  };

  const handleSelectedCompanyId = (value) => {
    dispatch(setSelectedCompanyId(value));
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <header>
          <h1 className="text-2xl font-bold text-gray-900">Job Routes</h1>
        </header>
        <div className="mt-4">
          <SelectBox
            data={msosData}
            defaultValue={selectedMsoId}
            onChange={handleSelectedMsoId}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a MSO'}
          />
        </div>
        <div className="mt-4">
          <SelectBox
            data={companiesData}
            defaultValue={selectedCompanyId}
            onChange={handleSelectedCompanyId}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Company'}
          />
        </div>
      </div>

      <SearchJobsDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  );
};

export default Jobroutes;