import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../features/auth/authSlice';

import Sidebar from './Sidebar';
import Header from './Header';

import Users from './users/Users';
import CreateUser from './users/CreateUser';
import EditUser from './users/EditUser';
import ViewUser from './users/ViewUser';

import MSOs from './msos/MSOs';
import CreateMSO from './msos/CreateMSO';

import Areas from './areas/Areas';
import ViewArea from './areas/ViewArea';
import CreateArea from './areas/CreateArea';
import EditArea from './areas/EditArea';

import Companies from './companies/Companies';
import CreateCompany from './companies/CreateCompany';
import EditCompany from './companies/EditCompany';

import Jobroutes from './billing/Jobroutes';

import Inventory from './Inventory';
import Welcome from './Welcome';

import Billing from './billing/Billing'
import BillJobs from './billing/BillJobs';
import JobDetails from './billing/JobDetails';
import LockJobs from './billing/LockJobs';
import GenerateExport from './billing/GenerateExport';
import GenerateInvoice from './billing/GenerateInvoices';
import ViewJobs from './billing/ViewJobs';

import Technicians from './technicians/Technicians';
import CreateTechnician from './technicians/CreateTechnician';
import ViewTechnician from './technicians/ViewTechnician';
import EditTechnician from './technicians/EditTechnician';


import Codes from './rateCodes/Codes';
import ViewCode from './rateCodes/ViewCode';
import EditCode from './rateCodes/EditCode';
import CreateCode from './rateCodes/CreateCode';

const Dashboard = () => {
  const token = useSelector(selectAuthToken);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 p-4 bg-gray-100">
          <Routes>
            <Route path="/users" element={<Users />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/users/:userId/edit" element={<EditUser />} />
            <Route path="/users/:userId" element={<ViewUser />} />
            <Route path="/areas" element={<Areas />} />
            <Route path="/areas/create" element={<CreateArea />} />
            <Route path="/areas/:areaId" element={<ViewArea />} />
            <Route path="/areas/:areaId/edit" element={<EditArea />} />

            <Route path="/rate_codes" element={<Codes />} />
            <Route path="/rate_codes/create" element={<CreateCode />} />
            <Route path="/rate_codes/:codeId" element={<ViewCode />} />
            <Route path="/rate_codes/:codeId/edit" element={<EditCode />} />

            <Route path="/companies" element={<Companies />} />
            <Route path="/companies/create_company" element={<CreateCompany />} />
            <Route path="/companies/:companyId/edit" element={<EditCompany />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/" element={<Welcome />} />
            <Route path="/msos" element={<MSOs />} />
            <Route path="/msos/create_mso" element={<CreateMSO />} />
            <Route path="/billing/*" element={<Billing />}>
              <Route path="bill_jobs" element={<BillJobs />} />
              <Route path="view_jobs" element={<ViewJobs />} />
              <Route path="job_routes" element={<Jobroutes />} />
              <Route path="job_details/:jobId" element={<JobDetails />} />
              <Route path="locked_jobs" element={<LockJobs />} />
              <Route path="generate_exports" element={<GenerateExport />} />
              <Route path="generate_invoices" element={<GenerateInvoice />} />
            </Route>
            <Route path="/technicians" element={<Technicians />} />
            <Route path="/technicians/create" element={<CreateTechnician />} />
            <Route path="/technicians/:techId" element={<ViewTechnician />} />
            <Route path="/technicians/:techId/edit" element={<EditTechnician />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;