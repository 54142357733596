import { useState } from 'react'
import 'tailwindcss/tailwind.css';

const SelectBox = ({ data, defaultValue, valueField, keyField, zeroOption, onChange }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    onChange(selectedValue);
  };

  return (
    <select
    value={value || ''}
    onChange={(e) => handleChange(e.target.value)}
    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
  >
    <option value="0">{zeroOption}</option>
    {data.map(item => (
      <option key={item[valueField]} value={item[valueField]}>
        {item[keyField]}
      </option>
    ))}
  </select>
  );
};

export default SelectBox;