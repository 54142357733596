import React from 'react';
import 'tailwindcss/tailwind.css';

const CancelBtn = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center text-xs bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 ml-2"
    >
      <svg
        className="h-5 w-5 text-white mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>
      {text && (<span className="ml-1">{text}</span>)}
    </button>
  );
};

export default CancelBtn;