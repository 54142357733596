import React from "react"

const FormPasswordInput = ({label, name, value, onChange, placeholder, required, formErrors, horizontal}) => {


  const LabelVElement = () => {
    return (
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
    )
  }

  const LabelHElement = () => {
    return (
      <div className="md:w-1/3">
        <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
          {label}
        </label>
      </div>
    )
  }

  const InputElement = () => {
    return (
      <input
        type="password"
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        id={name}
        name={name}
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        autoComplete={'off'}
      />
    )
  }

  const ErrorBox = () => {
    return (
      <>
        {formErrors && formErrors[name] && <p className="text-red-500 text-sm mt-1">{formErrors[name]}</p>}
      </>
    )
  }

  return (
    <>
      {horizontal ? (
        <div className="md:flex md:items-center mb-6">
          <LabelHElement />
          <div className="md:w-2/3">
            <InputElement />
          </div>
          <ErrorBox />
        </div>
      ) : (
        <div>
          <LabelVElement />
          <InputElement />
          <ErrorBox />
        </div>
      )}
    </>
  )
}

export default FormPasswordInput
