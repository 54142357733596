import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  data: [],
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  selectedMsoId: 0,
  searchQuery: '',
  selectedMsoId: localStorage.getItem('msoId') || 0
};

export const fetchInventories = createAsyncThunk(
  'inventories/all',
  async ({ msoId, page = 1, per = 25, search = '', sorting = [], navigate }) => {
    let sortBy = '';
    let direction = '';

    const sortField = sorting.find(s => s.selected);
    sortBy = sortField ? sortField.id : 'id';
    direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/inventories?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`;

    return fetchWithAuth(url, {}, navigate);
  }
);

const inventorySlice = createSlice({
  name: 'inventories',
  initialState,
  reducers: {
    clearInventories: () => initialState,
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedMsoId: (state, action) => {
      state.selectedMsoId = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInventories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchInventories.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    });
    builder.addCase(fetchInventories.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const {
  clearInventories,
  setPageSize,
  setSelectedMsoId,
  setSearchQuery,
} = inventorySlice.actions;

export default inventorySlice.reducer;
