import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';

import {
  setSuccess,
  setError,
  fetchTechnician
} from 'features/technician/techniciansSlice';
import 'tailwindcss/tailwind.css';

const ViewTechnician = () => {
  const { techId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { techData, loading, error } = useSelector((state) => state.technicians);

  useEffect(() => {
    dispatch(fetchTechnician(techId));
  }, [dispatch, techId]);

  setSuccess(false)
  setError(false)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleBack = () => {
    navigate('/technicians');
  };

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={`Technician Details: ${techData?.user_full_name || ''}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      {(loading || techData === null) ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error loading Technician details.</div>
      ) : (

          <div className="mt-6 w-1/2">
            <div className="mt-6 w-full mb-5">
              <div className="grid grid-cols-2 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">MSO</p>
                  <p>{techData?.mso_name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Company</p>
                  <p>{techData?.company_name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Area</p>
                  <p>{techData?.area_name || 'N/A'}</p>
                </div>
              </div>
            </div>

          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="font-bold">Tech ID:</p>
              <p>{techData.tech_id || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Pay Rate:</p>
              <p>{techData.pay_rate || 'N/A'}</p>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={() => navigate(`/technicians/${techId}/edit`)}
              className="bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit Technician
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTechnician;
