import React from 'react';

function Welcome() {
  return (
    <div className="text-2xl">
      Welcome
    </div>
  );
}

export default Welcome;