import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchRateCodes, fetchRateCode } from 'features/rateCodes/rateCodesSlice';
import { addMinimalJobRateCode } from 'features/jobRoutes/jobRateCodesSlice';
import SelectBox from '../../../fields/SelectBox';

const JobDetailsRateCodeModal = ({ isOpen, onClose, jobRoute }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const jobRouteId = jobRoute.id;
  const jobAreaId = jobRoute.area.id;
  const jobUserId = jobRoute.user.id;

  const [selectedRateCodeId, setSelectedRateCodeId] = useState(0);
  const [multiplier, setMultiplier] = useState(1);
  const [quantityLocked, setQuantityLocked] = useState(false);

  const { data: rateCodes, rateCode } = useSelector((state) => state.rateCodes);

  useEffect(() => {
    dispatch(fetchRateCodes({ areaId: jobAreaId, navigate }));
  }, [dispatch, jobRoute, jobAreaId, navigate]);

  useEffect(() => {
    if (selectedRateCodeId === 0) return;

    dispatch(fetchRateCode({ rateCodeId: selectedRateCodeId, areaId: jobAreaId, navigate }));
  }, [dispatch, selectedRateCodeId, jobAreaId, navigate]);

  useEffect(() => {
    if (rateCode === null) { return }
    setMultiplier(1)
    setQuantityLocked(rateCode.quantity_locked)
  }, [ rateCode ])

  const handleSubmit = (e) => {
    e.preventDefault();

    const rateCodeData = {
      job_route_id: jobRouteId,
      area_id: jobAreaId,
      multiplier: multiplier,
      rate_code_id: selectedRateCodeId,
      user_id: jobUserId,
    }

    dispatch(addMinimalJobRateCode({ jobRouteId, rateCodeData }));
    onClose();
  };

  const handleRateCodeChange = (rateCodeId) => {
    if(rateCodeId === '0') return;

    setSelectedRateCodeId(rateCodeId);
  }

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">
            Add Rate Code
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Rate Code
              </label>
              <SelectBox
                data={rateCodes}
                defaultValue={0}
                onChange={handleRateCodeChange}
                valueField={'id'}
                keyField={'name_for_list'}
                zeroOption={'Select a Rate Code'}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Multiplier
              </label>
              <input
                type="text"
                value={multiplier}
                onChange={(e) => setMultiplier(e.target.value)}
                className={`mt-1 w-full pl-3 pr-10 py-2 text-base ${(quantityLocked) ? 'bg-blue-100 border-blue-500' : 'bg-grey-100 border-gray-500' } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                disabled={quantityLocked}
              />
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 text-black rounded"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default JobDetailsRateCodeModal;
