import React from 'react';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

const ViewJobsBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/billing/view_jobs");
  };

  return (
    <button onClick={handleClick} className={`${btnClassName}`}>
      <svg className={`${icoClassName}`} width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
      <span className="ml-2">View Jobs</span>
    </button>
  );
};

export default ViewJobsBtn;