import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';
import { formatEnumKey } from 'utils/TextTools';
import { setError, setSuccess } from 'features/areas/areasSlice';

const initialState = {
  data: null,
  billing_statuses: [],
  statuses: [],
  loading: false,
  success: false,
  error: null,
};

export const generateExports = createAsyncThunk(
  'billing/generateExports',
  async ({ startDate, endDate, areaId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/generate_exports`;
    const response = await fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({
        start_date: startDate,
        end_date: endDate,
        area_id: areaId
      }),
    }, 'blob');


    const blob = response
    const urlBlob = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = urlBlob;
    link.download = 'tech.csv';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(urlBlob);

  }
);

export const viewJobs = createAsyncThunk(
  'billing/viewJobs',
  async ({ startDate, endDate, areaId }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');


    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/billing/view_jobs`;
      const response = await fetchWithAuth(url, {
        method: 'POST',
        body: JSON.stringify({
          start_date: startDate.toLocaleDateString('en-ca'),
          end_date: endDate.toLocaleDateString('en-ca'),
          area_id: areaId
        }),
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRateCodeTypeList = createAsyncThunk(
  'rateCodes/listTypes',
  async ({msoId, navigate}, { rejectWithValue }) => {
    try {

      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/rate_codes/list_types`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      setError(error.message)
      return rejectWithValue(error.message);
    }
  }
);


const billingSlice = createSlice({
  name: 'jobRoute',
  initialState,
  reducers: {
    setSelectedMsoId: (state, action) => {
      localStorage.setItem('msoId', action.payload);
      state.selectedMsoId = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      localStorage.setItem('companyId', action.payload);
      state.selectedCompanyId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(generateExports.pending, (state) => {
      state.success = false
      state.error = false;
      state.loading = true;
    })
    .addCase(generateExports.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.error = false;
    })
    .addCase(generateExports.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.success = false;
      state.error = action.error.message;
    })

    .addCase(viewJobs.pending, (state) => {
      state.loading = true;
      state.billing_statuses = [];
      state.statuses = [];
    })
    .addCase(viewJobs.fulfilled, (state, action) => {

      if (action.payload.billing_statuses) {
        action.payload.billing_statuses['all'] = '-1'
        let newBillingStatuses = {}

        Object.entries(action.payload.billing_statuses).map(([key, value]) => {
          return newBillingStatuses[formatEnumKey(key)] = value;
        })

        action.payload.billing_statuses = newBillingStatuses
      }

      if (action.payload.statuses) {
        action.payload.statuses['all'] = '-1'
        let newStatuses = {}

        Object.entries(action.payload.statuses).map(([key, value]) => {
          return newStatuses[formatEnumKey(key)] = value;
        })

        action.payload.statuses = newStatuses
      }

      state.loading = false;
      state.data = action.payload;
      state.error = false;
    })
    .addCase(viewJobs.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.billing_statuses = [];
      state.statuses = [];
      state.error = action.error.message;
    });
  },
});

export const {
  setSelectedMsoId,
  setSelectedCompanyId,
} = billingSlice.actions;

export default billingSlice.reducer;