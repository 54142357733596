import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitleBar from './HeaderTitleBar';
import MsoSelectBox from './dropdowns/MsoSelectBox';

import TosDataTable from './TosDataTable';
import 'tailwindcss/tailwind.css';

import { fetchMsos } from '../features/msos/msosSlice';
import {
  fetchInventories,
  clearInventories,
  setPageSize,
  setSelectedMsoId,
  setSearchQuery } from '../features/inventories/inventorySlice';

const Inventory = () => {
  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id', enableSorting: true },
    { header: 'Action Status', accessorKey: 'action_status_id' },
    { header: 'Inventory Type', accessorKey: 'type_name' },
    { header: 'Serial Number', accessorKey: 'serial_number' },
    { header: 'Date Received', accessorKey: 'date_received' },
    { header: 'Date Refreshed', accessorKey: 'date_refreshed' },
    { header: 'Date Issued', accessorKey: 'date_issued' },
    { header: 'Date Signed', accessorKey: 'date_signed' },
    { header: 'Date Installed', accessorKey: 'date_installed' },
    { header: 'Date Returned', accessorKey: 'date_returned' },
    { header: 'Area', accessorKey: 'area_name' },
    { header: 'Tech ID', accessorKey: 'tech_id' },
    { header: 'Account Number', accessorKey: 'account_number' },
    { header: 'Job Route', accessorKey: 'job_route_id' },
    { header: 'User', accessorKey: 'full_name' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const dispatch = useDispatch();

  const {
    data,
    loading,
    pageCount,
    totalItems,
    pageSize,
    selectedMsoId,
    searchQuery
  } = useSelector((state) => state.inventories);

  const [pageIndex, setPageIndex] = useState(0);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);


  const fetchInventoriesData = useCallback(() => {
    console.log('here')
    if (selectedMsoId) {
      dispatch(fetchInventories({
        msoId: selectedMsoId,
        page: pageIndex + 1,
        per: pageSize,
        search: searchQuery,
        sorting
      }));
    }
  }, [dispatch, selectedMsoId, pageIndex, pageSize, searchQuery, sorting]);


  useEffect(() => {
    if (selectedMsoId === 0) {
      dispatch(clearInventories());
    } else {
      fetchInventoriesData(selectedMsoId);
    }
  }, [ selectedMsoId, fetchInventoriesData, dispatch ])

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    dispatch(setSearchQuery(newSearchQuery));
    setPageIndex(0);
  };

  const handleSelectedMsoId = (value) => {
    dispatch(setSelectedMsoId(value));
  };

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Inventory'
          showBtn={false}
        />
        <div className="mt-4">
            <MsoSelectBox onChange={handleSelectedMsoId} value={selectedMsoId} />
        </div>
      </div>

      <TosDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  );
};

export default Inventory;