import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormSelectBox from "components/formHepers/FormSelectBox";
import { fetchMsos } from "features/msos/msosSlice";

const MsoSelectBox = ({ value, name="msoId", label, useTarget = true, onChange, horizontal=false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.msos);

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999, navigate }));
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);

  return (
    <FormSelectBox
      name={name}
      id={name}
      label={label}
      defaultValue={value}
      valueField={'id'}
      keyField={'name'}
      zeroOption={'Select a MSO'}
      onChange={(event) => onChange(useTarget ? event.target.value : event)}
      data={data}
      horizontal={horizontal}
    />
  )
}

export default MsoSelectBox;