import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithAuth } from '../../utils/ApiTools'

const initialState = {
  data: [],
  loading: false
};

export const fetchStates = createAsyncThunk('states/all',
    async ({ navigate }) => {

  const url = `${process.env.REACT_APP_API_URL}/api/v1/states`
  return fetchWithAuth(url, {}, navigate)
});


const statesSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    clearStates: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStates.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchStates.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.error = null;
    })
    .addCase(fetchStates.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  }
});

export const { clearStates } = statesSlice.actions;
export default statesSlice.reducer;