import { createSlice } from '@reduxjs/toolkit';
import { getSubDomain } from '../../utils/NetworkTools';

const subdomainSlice = createSlice({
  name: 'subdomain',
  initialState: {
    value: getSubDomain(),
  },
  reducers: {
    setSubdomain: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setSubdomain } = subdomainSlice.actions;

export const selectSubdomain = (state) => state.subdomain.value;

export default subdomainSlice.reducer;