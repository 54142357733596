import React, { useState, useEffect } from 'react';
import 'tailwindcss/tailwind.css';

const JobRouteBillingStatusBtn = ({ status, handler }) => {
  const [btnClass, setBtnClass] = useState('blue');
  const [btnText, setBtnText] = useState('blue');
  const [colText, setColText] = useState('Approved');

  useEffect(() => {
    if (status === 'approved') {
      setBtnClass('red');
      setBtnText('Revert to Pending');
      setColText('Approve');
    } else {
      setBtnClass('green');
      setBtnText('Approve');
      setColText('Pending');
    }
  }, [ status ])

  return (
    <>
      {colText}
      <button
        onClick={() => handler(status)}
        className={`text-xs bg-${btnClass}-500 text-white px-3 py-1 rounded hover:bg-${btnClass}-600 ml-2`}
      >
        {btnText}
      </button>
    </>
  );
};

export default JobRouteBillingStatusBtn;