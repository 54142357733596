import React, { useState } from 'react';
import EditBtn from '../buttons/EditBtn';
import SaveBtn from '../buttons/SaveBtn';
import CancelBtn from '../buttons/CancelBtn';

import 'tailwindcss/tailwind.css';

const EditableTextField = ({ fieldValue, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(fieldValue);

  const handleSave = () => {
    if (!value.trim()) { return; }

    setIsEditing(false);
    onSave(value);
  };

  const handleCancel = () => {
    setValue(fieldValue);
    setIsEditing(false);
  };

  return (
    <>
    {isEditing ? (
      <div className="flex items-center">
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="mt-1 block pl-3 pr-10 py-2 text-base bg-gray-100 border-gray-500 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
        <SaveBtn text={'Save'} onClick={handleSave} />
        <CancelBtn text={'Cancel'} onClick={handleCancel} />
      </div>
    ) : (
      <>
        <span className="flex items-left">
        {value}
        <EditBtn text={'Edit Job Number'} onClick={() => setIsEditing(true)} />
        </span>
      </>
    )}
    </>
  );
};

export default EditableTextField;