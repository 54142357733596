import React from 'react';
import 'tailwindcss/tailwind.css';

const TechPayReportBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;

  return (
    <button className={`${btnClassName}`}>
      <svg className={`${icoClassName}`}  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="12" width="6" height="8" rx="1" />  <rect x="9" y="8" width="6" height="12" rx="1" />  <rect x="15" y="4" width="6" height="16" rx="1" />  <line x1="4" y1="20" x2="18" y2="20" /></svg>
      <span className="ml-2">Tech Pay Report</span>
    </button>
  );
};

export default TechPayReportBtn;