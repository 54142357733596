import React from 'react';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

const JobNumberLink = ({ job_id, value }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();

    navigate(`/billing/job_details/${job_id}`);
  };

  return (
    <a href="job_details" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={handleClick}>{value}</a>
  );
};

export default JobNumberLink;