export const getSubDomain = () => {
  const { hostname } = window.location;
  const parts = hostname.split('.');

  if(parts[1] === 'localhost' && parts.length === 2) {
    return parts[0];
  }

  if (parts.length >= 3) {
    return parts[0];
  }

  return null;
};
