import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  data: [],
  rateCode: null,
  loading: false,
  error: null,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  selectedMsoId: localStorage.getItem('msoId') || 0
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchRateCodes = createAsyncThunk(
  'rateCodes/all',
  async ({areaId, navigate}, { rejectWithValue }) => {
    try {
      const msoId = localStorage.getItem('msoId');
      const companyId = localStorage.getItem('companyId');

      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/areas/${areaId}/rate_codes`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      console.log('error', error)
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMsoRateCodes = createAsyncThunk(
  'rateCodes/msoAll',
  async ({
    msoId,
    page = 1,
    per = 25,
    search = '',
    sorting = [],
    navigate }, { rejectWithValue }) => {

let sortBy = '';
let direction = '';

const sortField = sorting.find(s => s.selected);

sortBy = sortField ? sortField.id : 'id';
direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

try {
  const response = await fetchWithAuth(
    `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/rate_codes?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`
  , navigate);

  return response.data;
} catch (error) {
  return rejectWithValue(error.message);
}
});

export const fetchMsoRateCode = createAsyncThunk(
  'rateCodes/MsofetchById',
  async ({ codeId, navigate }, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/rate_codes/${codeId}`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchRateCode = createAsyncThunk(
  'rateCodes/fetchById',
  async ({ rateCodeId, areaId, navigate }, { rejectWithValue }) => {
    try {
      const msoId = localStorage.getItem('msoId');
      const companyId = localStorage.getItem('companyId');

      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/areas/${areaId}/rate_codes/${rateCodeId}`;
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const addRateCode = createAsyncThunk(
  'rateCodes/addRateCode',
  async ({formData, navigate}, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/rate_codes`,
        {
          method: 'POST',
          body: JSON.stringify({ rate_code: formData }),
        },
        navigate
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const editRateCode = createAsyncThunk(
  'rateCodes/editRateCode',
  async ({codeId, formData, navigate}, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/rate_codes/${codeId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ rate_code: formData }),
        },
        navigate
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


const rateCodesSlice = createSlice({
  name: 'jobRoute',
  initialState,
  reducers: {
    clearRateCodes: (state) => {
      state.data = null;
      state.error = null;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedMsoId: (state, action) => {
      state.selectedMsoId = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRateCodes.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchRateCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    })
    .addCase(fetchRateCodes.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })
    .addCase(fetchRateCode.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchRateCode.fulfilled, (state, action) => {
      state.loading = false;
      state.rateCode = action.payload;
      state.error = null;
    })
    .addCase(fetchRateCode.rejected, (state, action) => {
      state.loading = false;
      state.rateCode = null;
      state.error = action.error.message;
    })
    .addCase(fetchMsoRateCodes.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchMsoRateCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    })
    .addCase(fetchMsoRateCodes.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })

    .addCase(fetchMsoRateCode.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchMsoRateCode.fulfilled, (state, action) => {
      state.loading = false;
      state.rateCode = action.payload;
      state.error = null;
    })
    .addCase(fetchMsoRateCode.rejected, (state, action) => {
      state.loading = false;
      state.rateCode = null;
      state.error = action.error.message;
    })
    .addCase(editRateCode.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editRateCode.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.rateCode = action.payload;
    })
    .addCase(editRateCode.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(addRateCode.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    })
    .addCase(addRateCode.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.data.push(action.payload)
    })
    .addCase(addRateCode.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    });
  },
});

export const {
  setSelectedMsoId,
  selectedMsoId,
  selectedCompanyId,
  setPageSize,
  setSuccess,
  setError,
  clearRateCodes
} = rateCodesSlice.actions;

export default rateCodesSlice.reducer;


