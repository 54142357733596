import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHepers/FormTextInput';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';
import FormSelectBox from 'components/formHepers/FormSelectBox';
import MsoSelectBox from 'components/dropdowns/MsoSelectBox';
import CompanySelectBox from 'components/dropdowns/CompanySelectBox';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';
import { editTechnician, fetchTechnician  } from 'features/technician/techniciansSlice';
import {
  setSelectedAreaId,
  setSelectedCompanyId,
  setSelectedMsoId,
  clearUsers,
  fetchUserList
} from 'features/users/usersSlice';

import 'tailwindcss/tailwind.css';

const EditTechnician = () => {
  const { techId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    success,
    error,
    techData
  } = useSelector((state) => state.technicians);

  const {
    userData,
    selectedCompanyId,
    selectedAreaId
  } = useSelector((state) => state.users);

  // const [currentUser, setCurrentUser] = useState('')
  // const [selectedUserId, setSelectedUserId] = useState(0);

  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    user_id: 0,
    company_id: 0,
    area_id: 0,
    tech_id: '',
    mso_id: 0,
    pay_rate: ''
  });

  useEffect(() => {
    dispatch(fetchTechnician(techId));
  }, [dispatch, techId]);

  useEffect(() => {
    if (techData) {
      setFormData({
        mso_id: techData.mso_id || '',
        company_id: techData.company_id || 0,
        area_id: techData.area_id || 0,
        user_id: techData.user_id,
        tech_id: techData.tech_id || '',
        pay_rate: techData.pay_rate || ''
      });
      setSelectedMsoId(techData.mso_id);
      setSelectedCompanyId(techData.company_id);
      setSelectedAreaId(techData.area_id);
      //setSelectedUserId(techData.user_id);
    }

  }, [techData]);

  const validateForm = () => {
    let newErrors = {};

    if (!formData.user_id) {
      newErrors.user_id = 'A User is required';
    }

    if (!formData.tech_id) {
      newErrors.tech_id = 'A Tech ID is required';
    }

    if (!formData.mso_id) {
      newErrors.mso_id = 'A MSO ID is required';
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const fetchUsersData = useCallback(() => {
    if (parseInt(formData.mso_id) === 0 || typeof(formData.mso_id) === 'undefined') {
      dispatch(clearUsers());
    } else {
      // setCurrentUser('');
      dispatch(fetchUserList({
        msoId: formData.mso_id,
        companyId: selectedCompanyId,
        areaId: selectedAreaId,
        navigate }));
    }

  }, [dispatch,
    formData.mso_id,
    selectedCompanyId,
    selectedAreaId,
    navigate]);

  useEffect(() => {
    fetchUsersData();
  }, [fetchUsersData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch(name) {
      case 'mso_id':
        dispatch(setSelectedMsoId(value));
        break;
      case 'company_id':
        dispatch(setSelectedCompanyId(value));
        break;
      case 'area_id':
        dispatch(setSelectedAreaId(value));
        break;
      default:
        break;
    }

    if (name === 'mso_id' || name === 'company_id') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      const newData = formData;
      newData[name] = value;
      setFormData(newData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});

    if (validateForm()) {
      dispatch(editTechnician({techId, formData}))
    }
  };

  const handleBack = () => {
    navigate('/technicians');
  };

  return (

    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={`Edit Technician: ${techData?.user_full_name}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`Technician "${formData.tech_id}" for "${techData?.user_full_name}" Updated`}
        success={success}
        error={error}
      />

      <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">

        <MsoSelectBox
          label={'MSO'}
          name={'mso_id'}
          value={formData.mso_id}
          onChange={handleChange}
          useTarget={false}
        />

        <CompanySelectBox
          label={'Company'}
          name={'company_id'}
          value={formData.company_id}
          onChange={handleChange}
          useTarget={false}
          msoId={formData.mso_id}
        />

        <AreaSelectBox
          label={'Area'}
          name={'area_id'}
          value={formData.area_id}
          onChange={handleChange}
          useTarget={false}
          msoId={formData.mso_id}
        />

        <FormSelectBox
          name={'user_id'}
          id={'user_id'}
          label={'User'}
          defaultValue={formData.user_id}
          valueField={'id'}
          keyField={'full_name'}
          zeroOption={'Select an User'}
          onChange={handleChange}
          data={userData}
        />

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Tech ID'}
            name="tech_id"
            value={formData.tech_id}
            onChange={handleChange}
            placeholder="Enter a Tech ID"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Pay Rate'}
            name="pay_rate"
            value={formData.pay_rate}
            onChange={handleChange}
            placeholder="Enter a Tech ID"
            formErrors={formErrors}
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Edit Technician
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditTechnician;
