import React, { useEffect, useCallback, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'tailwindcss/tailwind.css';
import BillingUploader from './components/BillingUploader';
import { fetchMsos } from '../../features/msos/msosSlice';

const BillJobs = () => {
  const dispatch = useDispatch();
  const [selectedMsoId, setSelectedMsoId] = useState(0);
  const { data: msosData } = useSelector((state) => state.msos);

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);

  const handleSelectedMsoId = (value) => {
    setSelectedMsoId(value);
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <header>
          <h1 className="text-2xl font-bold text-gray-900">Bill Jobs</h1>
        </header>

        <div className="mt-4">
          <select
            id="mso-dropdown"
            value={selectedMsoId || ''}
            onChange={(e) => handleSelectedMsoId(e.target.value)}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-900 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select an MSO</option>
            {msosData.map(mso => (
              <option key={mso.id} value={mso.id}>
                {mso.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4">
          <BillingUploader bucket="wfxexports" msoId={selectedMsoId} label="WFX Export" types=".xlsx, .xml" />
          <BillingUploader bucket="wfxcheckins" msoId={selectedMsoId} label="WFX Checkin" types=".xlsx, .xml" />
          <BillingUploader bucket="wfxeow" msoId={selectedMsoId} label="End of Week Invoice" types=".xlsx, .xml" />
        </div>
      </div>
    </>
  );
};

export default BillJobs;