import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormSelectBox from "components/formHepers/FormSelectBox";
import { clearCompanies, fetchCompanies } from "features/companies/companiesSlice";

const CompanySelectBox = ({ msoId, name="companyId", value, label, useTarget=true, onChange, horizontal = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.companies);

  const fetchCompaniesData = useCallback(() => {
    if(msoId === 0 || typeof(msoId) === 'undefined') return;

    dispatch(fetchCompanies({ msoId, page: 1, per: 9999, navigate }));
  }, [ dispatch, msoId, navigate]);

  useEffect(() => {
    if (parseInt(msoId) === 0) {
      dispatch(clearCompanies());
    } else {
      fetchCompaniesData(msoId);
    }
  }, [ msoId, fetchCompaniesData, dispatch ])

  return (
    <FormSelectBox
      name={name}
      id={name}
      defaultValue={value}
      label={label}
      valueField={'id'}
      keyField={'name'}
      zeroOption={`Select a Company`}
      onChange={(event) => onChange(useTarget ? event.target.value : event)}
      data={data}
      horizontal={horizontal}
    />
  )
}

export default CompanySelectBox;