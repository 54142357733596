import React from 'react';
import { useNavigate } from 'react-router-dom';

import 'tailwindcss/tailwind.css';

const LockJobsBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/billing/locked_jobs");
  };

  return (
    <button onClick={handleClick} className={`${btnClassName}`}>
      <svg className={`${icoClassName}`} viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round">  <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />  <path d="M7 11V7a5 5 0 0 1 9.9-1" /></svg>
      <span className="ml-2">Lock Jobs</span>
    </button>
  );
};

export default LockJobsBtn;