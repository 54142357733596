import React from 'react';

const AreYouSureModal = ({ isOpen, onClose, onConfirm, dialog }) => {
  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-full max-w-md">
          <h3 className="text-lg font-semibold mb-4">Confirmation</h3>
            <p className="mb-6">{dialog}</p>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-2 bg-gray-300 text-black rounded"
              >
                No
              </button>
              <button
                type="submit"
                onClick={onConfirm}
                className="px-6 py-2 bg-red-500 text-white rounded"
              >
                Yes
              </button>
            </div>
        </div>
      </div>
    )
  );
};

export default AreYouSureModal;
