import React from 'react';
import 'tailwindcss/tailwind.css';

const EditBtn = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center text-xs bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 ml-2"
    >
      <svg
        className="h-5 w-5 text-white mr-2"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"/>
        <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
        <line x1="16" y1="5" x2="19" y2="8" />
      </svg>

      {text && (<span className="ml-1">{text}</span>)}
    </button>
  );
};

export default EditBtn;