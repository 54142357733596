import React, { useState } from "react";

const FormSubmissionResult = ({ successText, success, error}) => {
  return (
    <>
      {success === true && (
        <p className="p-2 mt-2 mb-2 text-green-900 rounded-sm mb-6 bg-green-200 border-green-400 border text-center">
          {successText}
        </p>
      )}

      {error && (
        <p className="p-2 mt-2 mb-2 text-red-900 rounded-sm mb-6 bg-red-200 border-red-400 border text-center">
          {error}
        </p>
      )}
    </>
  )
}

export default FormSubmissionResult