import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  full_name: '',
  address_s_1: '',
  address_2: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  phone_number: '',
  status: '',
  role: 1,
};

const savedAuthUser = localStorage.getItem('authUser');
const initialAuthUserState = savedAuthUser ? JSON.parse(savedAuthUser) : initialState;

const authUserSlice = createSlice({
  name: 'authUser',
  initialState: initialAuthUserState,
  reducers: {
    setAuthUser: (state, action) => {
      const newState = { ...state, ...action.payload };
      localStorage.setItem('authUser', JSON.stringify(newState));
      return newState;
    },
    clearAuthUser: () => {
      localStorage.removeItem('authUser');
      return initialState;
    },
  },
});

export const { setAuthUser, clearAuthUser } = authUserSlice.actions;
export default authUserSlice.reducer;