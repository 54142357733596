import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import TosDataTable from '../TosDataTable';
import 'tailwindcss/tailwind.css';
import {
  clearTechnicians,
  setPageSize,
  setSuccess,
  setError,
  setSelectedMsoId,
  fetchTechnicians
} from 'features/technician/techniciansSlice';

import MsoSelectBox from '../dropdowns/MsoSelectBox';
import HeaderTitleBar from '../HeaderTitleBar';

const Technicians = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data,
    loading,
    pageCount,
    totalItems,
    pageSize,
    selectedMsoId,
  } = useSelector((state) => state.technicians);

  const handleSelectedMsoId = (value) => {
    dispatch(setSelectedMsoId(value));
  }

  const ViewTechnician = ({ field, row }) => {
    return <Link
      to={`/technicians/${row.original.id}`}
      onClick={() => { navigate( `/technicians/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const ViewUser = ({ field, row }) => {
    return <Link
      to={`/users/${row.original.id}`}
      onClick={() => { navigate( `/users/${row.original.id}` ) }}
      className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
        {row.original[field]}
      </Link>;
  }

  const columns = useMemo(() => [
    { header: 'ID', accessorKey: 'id' },
    {
      header: 'Tech ID',
      accessorKey: 'tech_id',
      cell: ({ getValue, row }) => <ViewTechnician field={'tech_id'} value={getValue()} row={row} />
    },
    {
      header: 'User',
      accessorKey: 'user_full_name',
      cell: ({ getValue, row }) => <ViewUser field={'user_full_name'} value={getValue()} row={row} />
    },
    { header: 'Company', accessorKey: 'company_name' },
    { header: 'Area', accessorKey: 'area_name' },
    { header: 'MSO', accessorKey: 'mso_name' },
    { header: 'Pay Rate', accessorKey: 'pay_rate' },
    { header: 'Created At', accessorKey: 'created_at' },
    { header: 'Updated At', accessorKey: 'updated_at' },
  ], []);

  const [pageIndex, setPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [sorting, setSorting] = useState([{ id: 'id', desc: false, selected: true }]);
  const [sortOverride, setSortOverride] = useState(true);

  setSuccess(false)
  setError(false)
  const fetchTechData = useCallback(() => {
    if (typeof(selectedMsoId) === 'undefined') {
      return;
    }

    dispatch(fetchTechnicians({
      msoId: selectedMsoId,
      page: pageIndex + 1,
      per: pageSize,
      search: searchQuery,
      sorting,
      navigate
    }));
  }, [ dispatch,
    selectedMsoId,
    pageIndex,
    pageSize,
    searchQuery,
    sorting,
    navigate
  ]);

  useEffect(() => {
    if (parseInt(selectedMsoId) === 0) {
      dispatch(clearTechnicians());
    } else {
      fetchTechData();
    }
  }, [dispatch, selectedMsoId, fetchTechData]);

  const handlePageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    if (newPageSize !== pageSize) dispatch(setPageSize(newPageSize));
  };

  const handleSortingChange = (newSorting) => {
    setSorting(newSorting);
    setSortOverride(!sortOverride);
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
    setPageIndex(0);
  };

  const handleCreateClick = () => {
    navigate('/technicians/create')
  }

  return (
    <>
      <div className="p-4 mb-6 bg-white shadow rounded-lg">
        <HeaderTitleBar
          title='Technicians'
          showBtn={true}
          btnTitle='Create Technician'
          btnClick={handleCreateClick}
        />
        <div className="mt-4">
          <MsoSelectBox onChange={handleSelectedMsoId} value={selectedMsoId} />
        </div>
      </div>
      <TosDataTable
        columns={columns}
        data={data}
        pageCount={pageCount}
        totalItems={totalItems}
        pageIndex={pageIndex}
        pageSize={pageSize}
        sorting={sorting}
        loading={loading}
        onPageChange={handlePageChange}
        onPageSizeChange={(size) => handlePageChange(pageIndex, size)}
        onSortingChange={handleSortingChange}
        onSearchChange={handleSearchChange}
      />
    </>
  )

}

export default Technicians
