import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWithAuth } from '../../utils/ApiTools'

const initialState = {
  data: [],
  userData: null,
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  user_types: [],
  roles: [],
  statuses: [],
  success: false,
  selectedMsoId: localStorage.getItem('msoId') || 0
};

const errorHandler = (error) => {
  return error.response?.data?.message || error.message || 'Something went wrong';
}

export const fetchUsers = createAsyncThunk('users/all',
    async ({ msoId,
      user_type,
      role,
      status,
      page = 1,
      per = 25,
      search = '',
      sorting = [],
      navigate
    }) => {


  let queryParams = new URLSearchParams({
    mso_id: msoId,
    page: page,
    per: per,
  });

  if (search) {
    queryParams.append('search', search)
  }

  let sortBy = '';
  let direction = '';
  const sortField = sorting.find(s => s.selected);

  sortBy = sortField ? sortField.id : 'id';
  direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

  if (sortBy) {
    queryParams.append('sort_by', sortBy);
    queryParams.append('direction', direction);
  }

  if (user_type) {
    queryParams.append('user_type', user_type);
  }
  if (role) {
    queryParams.append('role', role);
  }
  if (status) {
    queryParams.append('status', status);
  }

  const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/users?${queryParams.toString()}`

  return fetchWithAuth(url, {}, navigate);
});


  export const fetchUserList = createAsyncThunk('users/list',
    async ({ msoId, companyId, areaId, page = 1, per = 9999, navigate }, { rejectWithValue }) => {

    let queryParams = new URLSearchParams({
      mso_id: msoId,
      per: 9999,
      page: 1
    });

    // if (companyId) {
    //   queryParams.append('company_id', companyId);
    // }

    // if (areaId) {
    //   queryParams.append('area_id', areaId);
    // }

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/users?${queryParams.toString()}`
      const response = await fetchWithAuth(url, {}, navigate);

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  });

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (userId, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/users/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const fetchUserEnums = createAsyncThunk(
  'user/fetchUserEnums',
  async () => {
    try {
      const response = await fetchWithAuth(`${process.env.REACT_APP_API_URL}/api/v1/users/user_enums`);

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  }
);

export const editUser = createAsyncThunk(
  'user/editUser',
  async ({ userId, formData, navigate }, { rejectWithValue }) => {
    const msoId = formData.mso_id;

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/users/${userId}`,
        {
          method: 'PUT',
          body: JSON.stringify({ user: formData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);

export const addUser = createAsyncThunk(
  'user/addUser',
  async (userData, { rejectWithValue }) => {
    const msoId = localStorage.getItem('msoId');

    try {
      const response = await fetchWithAuth(
        `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/users`,
        {
          method: 'POST',
          body: JSON.stringify({ user: userData }),
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(errorHandler(error));
    }
  }
);


const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUsers: () => {
      return initialState;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedMsoId: (state, action) => {
      state.selectedMsoId = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload;
    },
    setSelectedAreaId: (state, action) => {
      state.selectedAreaId = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    })
    .addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    })

    .addCase(fetchUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    })
    .addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
    .addCase(fetchUserList.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchUserList.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.error = null;
    })
    .addCase(fetchUserList.rejected, (state, action) => {
      state.loading = false;
      state.userData = [];
      state.error = action.error.message;
    })
    .addCase(editUser.pending, (state) => {
      state.loading = true;
      state.success = false
      state.error = null;
    })
    .addCase(editUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.userData = action.payload;
    })
    .addCase(editUser.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(addUser.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    })
    .addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true
      state.data.push(action.payload)
    })
    .addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      state.success = false
      state.error = action.payload;
    })
    .addCase(fetchUserEnums.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchUserEnums.fulfilled, (state, action) => {
      state.loading = false;
      state.user_types = action.payload.user_types;
      state.roles = action.payload.roles;
      state.statuses = action.payload.statuses;
      state.error = null;
    })
    .addCase(fetchUserEnums.rejected, (state, action) => {
      state.loading = false;
      state.enums = {};
      state.error = action.error.message;
    });
}
});


export const {
  clearUsers,
  clearFormData,
  setPageSize,
  setSelectedMsoId,
  setSelectedCompanyId,
  setSelectedAreaId,
  setSuccess,
  setError
} = usersSlice.actions;
export default usersSlice.reducer;