import React from 'react';
import 'tailwindcss/tailwind.css';

const SaveBtn = ({ text, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center text-xs bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 ml-2"
    >
      <svg
        className="h-5 w-5 text-white"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
          <polyline points="17 21 17 13 7 13 7 21" />
          <polyline points="7 3 7 8 15 8" />
      </svg>


      {text && (<span className="ml-1">{text}</span>)}
    </button>
  );
};

export default SaveBtn;