import React from "react"

const HeaderTitleBar = (props) => {
  const {
    title,
    showBtn,
    btnTitle,
    btnClick
  } = props

  return (
    <header className="flex items-center justify-between">
    <h1 className="text-2xl font-semi text-gray-900">{title}</h1>
      {showBtn && (
        <button onClick={btnClick} className="flex items-center bg-blue-500 text-white font-semi py-1 px-2 space-x-6 rounded hover:bg-blue-700">
          <svg
            className="h-6 w-6 text-white mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
              <line x1="12" y1="8" x2="12" y2="16" />
              <line x1="8" y1="12" x2="16" y2="12" />
          </svg>
          {btnTitle}
        </button>
      )}
    </header>
  )
}

export default HeaderTitleBar
