import React from "react"

const FormSelectBox = ({
    label,
    name,
    onChange,
    required = false,
    formErrors,
    data,
    defaultValue,
    valueField,
    keyField,
    useDefault,
    zeroOption,
    horizontal
  }) => {


  const LabelVElement = () => {
    if (!label) return;

    return (
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
    )
  }

  const LabelHElement = () => {
    if (!label) return;

    return (
      <div className="md:w-1/3">
        <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
          {label}
        </label>
      </div>
    )
  }

  const InputElement = () => {
    let defaultItem = null

    if (useDefault && (defaultValue === 0 || defaultValue === '' || defaultValue === null)) {
      defaultItem = data && data.find(item => item['default']);
    }

    return (
      <select
        name={name}
        id={name}
        defaultValue={defaultItem ? defaultItem[valueField] : defaultValue || ''}
        onChange={onChange}
        required={required}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      >
        <option value="0">{zeroOption}</option>
        {data && data.map((item, index) => (
          <option key={index} value={item[valueField]}>
            {item[keyField]}
          </option>
        ))}
      </select>
    )
  }

  const ErrorBox = () => {
    return (
      <>
        {formErrors && formErrors[name] && <p className="text-red-500 text-sm mt-1">{formErrors[name]}</p>}
      </>
    )
  }

  return (
    <>
      {horizontal ? (
        <div className="md:flex md:items-center mb-6">
          <LabelHElement />
          <div className="md:w-2/3">
            <InputElement />
          </div>
          <ErrorBox />
        </div>
      ) : (
        <div>
          <LabelVElement />
          <InputElement />
          <ErrorBox />
        </div>
      )}
    </>
  )
}

export default FormSelectBox

