import { combineReducers } from '@reduxjs/toolkit';
import subdomainReducer from '../features/subdomain/subdomainSlice';
import authReducer from '../features/auth/authSlice';
import authUserReducer from '../features/auth/authUserSlice';
import siteFeaturesReducer from '../features/siteFeatures/siteFeaturesSlice';
import msosReducer from '../features/msos/msosSlice';
import areasReducer from '../features/areas/areasSlice';
import companiesReducer from '../features/companies/companiesSlice';
import usersReducer from '../features/users/usersSlice';
import jobRoutesFilterReducer from '../features/jobRoutes/jobRouteFilterSlice';
import jobRouteSlice from 'features/jobRoutes/jobRouteSlice';
import jobRouteNotesSlice from 'features/jobRoutes/jobRouteNotesSlice';
import jobRateCodesSlice from 'features/jobRoutes/jobRateCodesSlice';
import inventoriesReducer from '../features/inventories/inventorySlice';
import rateCodesSlice from 'features/rateCodes/rateCodesSlice';
import lockedJobsSlice from 'features/lockedJobs/lockedJobsSlice';
import billingSlice from 'features/billing/billingSlice';
import statesSlice from 'features/states/statesSlice';
import techniciansSlice from 'features/technician/techniciansSlice';

const appReducer = combineReducers({
  auth: authReducer,
  subdomain: subdomainReducer,
  siteFeatures: siteFeaturesReducer,
  authUser: authUserReducer,
  msos: msosReducer,
  areas: areasReducer,
  companies: companiesReducer,
  users: usersReducer,
  jobRouteFilter: jobRoutesFilterReducer,
  jobRoute: jobRouteSlice,
  inventories: inventoriesReducer,
  jobRouteNotes: jobRouteNotesSlice,
  jobRateCodes: jobRateCodesSlice,
  rateCodes: rateCodesSlice,
  lockedJobs: lockedJobsSlice,
  billing: billingSlice,
  states: statesSlice,
  technicians: techniciansSlice
});

export default appReducer;