import { configureStore } from '@reduxjs/toolkit';
import appReducer from '../reducers';
import authMiddleware from '../middleware/authMiddleware';

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authMiddleware),
});

export default store;