import { useState } from 'react'
import EditBtn from '../buttons/EditBtn';
import SaveBtn from '../buttons/SaveBtn';
import CancelBtn from '../buttons/CancelBtn';

import 'tailwindcss/tailwind.css';
import DatePicker from 'tailwind-datepicker-react';

const EditableDateField = ({ fieldValue, btnText, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(fieldValue);
  const [show, setShow] = useState(false)

  const handleSave = () => {
    setIsEditing(false);
    onSave(value);
  };

	const handleChange = (selectedDate) => {
		setValue(selectedDate.toLocaleDateString('en-ca'))
	}

  const handleClose = (state) => {
		setShow(state)
	}

  const handleCancel = (state) => {
    setValue(fieldValue);
    setShow(state)
    setIsEditing(false);
  };

  const options = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    defaultDate: new Date(fieldValue),
    weekDays: ["M", "T", "W", "T", "F", "S", "S"],
    theme: { input: 'rounded-sm' },
    datepickerClassNames: 'mt-60'
  }

  return (
    <>
    {isEditing ? (
      <div className="flex items-center">
        <DatePicker
          onChange={handleChange}
          show={show}
          setShow={handleClose}
          options={options}
        />
        <SaveBtn text={'Save'} onClick={handleSave} />
        <CancelBtn text={'Cancel'} onClick={handleCancel} />
      </div>
    ) : (
      <>
        <span className="flex items-left">
        {value}
        <EditBtn text={btnText} onClick={() => setIsEditing(true)} />
        </span>
      </>
    )}
    </>
  );
};

export default EditableDateField;