import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import {
  setSuccess,
  setError,
  fetchUser
} from 'features/users/usersSlice';
import 'tailwindcss/tailwind.css';

const ViewUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData, loading, error } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  setSuccess(false)
  setError(false)

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleBack = () => {
    navigate('/users');
  };

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={`User Details: ${userData?.first_name || ''} ${userData?.last_name || ''}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      {(loading || userData === null) ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error loading user details.</div>
      ) : (

          <div className="mt-6 w-1/2">
            <div className="mt-6 w-full mb-5">
              <div className="grid grid-cols-2 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">MSO</p>
                  <p>{userData?.mso?.name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Company</p>
                  <p>{userData?.company?.name || 'N/A'}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div className='flex gap-2 '>
                  <p className="font-semibold">Area</p>
                  <p>{userData?.area?.name || 'N/A'}</p>
                </div>
              </div>
            </div>

          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <p className="font-bold">First Name:</p>
              <p>{userData.first_name || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Last Name:</p>
              <p>{userData.last_name || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Email:</p>
              <p>{userData.email || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Phone Number:</p>
              <p>{userData.phone_number || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Address 1:</p>
              <p>{userData.address_1 || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Address 2:</p>
              <p>{userData.address_2 || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">City:</p>
              <p>{userData.city || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">State:</p>
              <p>{userData.state?.name || 'N/A'}</p>
            </div>
            <div>
              <p className="font-bold">Zip Code:</p>
              <p>{userData.zip || 'N/A'}</p>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={() => navigate(`/users/${userId}/edit`)}
              className="bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Edit User
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewUser;
