import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHepers/FormTextInput';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';
import FormStatesSelectBox from 'components/formHepers/FormStatesSelectBox';
import MsoSelectBox from 'components/dropdowns/MsoSelectBox';
import { addArea } from 'features/areas/areasSlice';

import 'tailwindcss/tailwind.css';

const CreateArea = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    success,
    error
  } = useSelector((state) => state.areas);

  const [formErrors, setFormErrors] = useState({})
  const [formData, setFormData] = useState({
    mso_id: 0,
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: 0,
    zip: ''
  });

  const validateForm = () => {
    let newErrors = {};

    if (!formData.name) {
      newErrors.name = 'A name is required';
    }

    setFormErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {

    const { name, value } = e.target;

    if (name === 'mso_id') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      const newData = formData;
      newData[name] = value;
      setFormData(newData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors({});
    if (validateForm()) {
      dispatch(addArea({formData}))
    }
  };

  const handleBack = () => {
    navigate('/areas');
  };

  return (

    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={'Create Area'}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`Area "${formData.name}" Created`}
        success={success}
        error={error}
      />

      <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">

        <MsoSelectBox
          label={'MSO'}
          name={'mso_id'}
          value={formData.mso_id}
          onChange={handleChange}
          useTarget={false}
        />

        <FormTextInput
          label={'Name'}
          name={'name'}
          id={'name'}
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter a Name"
          formErrors={formErrors}
          required={true}
        />

        <FormTextInput
          label={'Address 1'}
          name="address_1"
          value={formData.address_1}
          onChange={handleChange}
          placeholder="Enter an Address"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Address (continued)'}
          name="address_2"
          value={formData.address_2}
          onChange={handleChange}
          placeholder="Enter an Address (continued)"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'City'}
          name="city"
          value={formData.city}
          onChange={handleChange}
          placeholder="Enter a City"
          formErrors={formErrors}
        />

        <FormStatesSelectBox
          state_id={formData.state?.id || 0}
          required={false}
          formErrors={formErrors}
          onChange={handleChange}
        />

        <FormTextInput
          label={'Zip'}
          name="zip"
          value={formData.zip}
          onChange={handleChange}
          placeholder="Enter a Zip Code"
          formErrors={formErrors}
        />

        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Area
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateArea;
