import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithAuth } from '../../utils/ApiTools';

const initialState = {
  data: [],
  loading: false,
  pageCount: 0,
  totalItems: 0,
  pageSize: 25,
  selectedMsoId: 0,
  selectedCompanyId: '',
  selectedAreaId: '',
  selectedUserId: '',
  searchQuery: '',
};

export const fetchJobRoutes = createAsyncThunk(
  'jobRoutes/all',
  async ({ msoId, companyId, areaId, userId, page = 1, per = 25, search = '', sorting = [], navigate }) => {
    let sortBy = '';
    let direction = '';

    const sortField = sorting.find(s => s.selected);
    sortBy = sortField ? sortField.id : 'id';
    direction = sortField ? (sortField.desc ? 'desc' : 'asc') : 'asc';

    const url = `${process.env.REACT_APP_API_URL}/api/v1/msos/${msoId}/companies/${companyId}/job_routes?page=${page}&per=${per}&search=${search}&sort_by=${sortBy}&direction=${direction}`;

    return fetchWithAuth(url, {}, navigate);
  }
);

const jobRouteFilterSlice = createSlice({
  name: 'jobRouteFilter',
  initialState,
  reducers: {
    clearJobRoutes: () => initialState,
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSelectedMsoId: (state, action) => {
      localStorage.setItem('msoId', action.payload);
      state.selectedMsoId = action.payload;
    },
    setSelectedCompanyId: (state, action) => {
      localStorage.setItem('companyId', action.payload);
      state.selectedCompanyId = action.payload;
    },
    setSelectedAreaId: (state, action) => {
      localStorage.setItem('areaId', action.payload);
      state.selectedAreaId = action.payload;
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobRoutes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchJobRoutes.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.pageCount = action.payload.meta.pagination.total_pages;
      state.totalItems = action.payload.meta.pagination.total_items;
      state.error = null;
    });
    builder.addCase(fetchJobRoutes.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
});

export const {
  clearJobRoutes,
  setPageSize,
  setSelectedMsoId,
  setSelectedCompanyId,
  setSelectedAreaId,
  setSelectedUserId,
  setSearchQuery,
} = jobRouteFilterSlice.actions;

export default jobRouteFilterSlice.reducer;
