import React from 'react';
import 'tailwindcss/tailwind.css';

const HoursApprovalBtn = ({ btnTheme }) => {
  const { btnClassName, icoClassName } = btnTheme;

  return (
    <button className={`${btnClassName}`}>
      <svg className={`${icoClassName}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>
      <span className="ml-2">Hours Approval</span>
    </button>
  );
};

export default HoursApprovalBtn;