import React from 'react';
import { toggleSidebar } from '../features/siteFeatures/siteFeaturesSlice';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

function Sidebar() {
  const sidebarOpen = useSelector((state) => state.siteFeatures.sidebarOpen);
  const dispatch = useDispatch();

  return (
    <div className={`fixed inset-0 z-30 transition-transform transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:relative lg:translate-x-0 lg:w-40 bg-gray-800 text-white flex flex-col`}>
      <nav className="flex-1 px-2 space-y-2">
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/users" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          Users
        </NavLink>
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/technicians" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          Technicians
        </NavLink>
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/msos" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          MSOs
        </NavLink>
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/areas" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          Areas
        </NavLink>
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/companies" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          Companies
        </NavLink>
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/inventory" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          Inventory
        </NavLink>
        <NavLink onClick={() => dispatch(toggleSidebar())} to="/billing" className="block px-4 py-2 text-gray-200 hover:bg-gray-700 rounded">
          Billing
        </NavLink>
      </nav>
    </div>
  );
}

export default Sidebar;