import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import FormTextInput from 'components/formHepers/FormTextInput';
import FormPasswordInput from 'components/formHepers/FormPasswordInput';
import FormStatesSelectBox from 'components/formHepers/FormStatesSelectBox';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';
import FormSelectBox from 'components/formHepers/FormSelectBox';
import { editUser } from 'features/users/usersSlice';
import 'tailwindcss/tailwind.css';
import { fetchUser } from 'features/users/usersSlice';
import { fetchUserEnums } from "features/users/usersSlice";
import MsoSelectBox from 'components/dropdowns/MsoSelectBox';
import CompanySelectBox from 'components/dropdowns/CompanySelectBox';
import AreaSelectBox from 'components/dropdowns/AreaSelectBox';


const EditUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: 0,
    zip: '',
    user_type: 0,
    role: 0,
    status: 0,
    mso_id: 0,
    company_id: 0,
    area_id: 0
  });

  const [formErrors, setFormErrors] = useState({});
  const { user_types, roles, statuses } = useSelector((state) => state.users);

  const fetchEnumData = useCallback(() => {
    dispatch(fetchUserEnums());
  }, [dispatch]);

  useEffect(() => {
    fetchEnumData();
  }, [fetchEnumData]);

  const { userData, loading, error, success } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (userData) {
      setFormData({
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        phone_number: userData.phone_number || '',
        address_1: userData.address_1 || '',
        address_2: userData.address_2 || '',
        city: userData.city || '',
        state_id: userData?.state?.id || 0,
        zip: userData.zip || '',
        user_type: userData.user_type ,
        role: userData.role ,
        status: userData.status,
        mso_id: userData?.mso?.id,
        company_id: userData?.company?.id,
        area_id: userData?.area?.id
      });
    }

  }, [userData]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.first_name) {
      newErrors.first_name = 'First Name is required';
    }

    if (!formData.last_name) {
      newErrors.last_name = 'Last Name is required';
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newData = formData;
    if (name === 'user_type' || name === 'status' || name === 'role') {
      if (value === 0) {
        value = null;
      }
    }

    newData[name] = value;
    setFormData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});

    if (validateForm()) {
      dispatch(editUser({ userId, formData, navigate }));
    }
  };

  const handleBack = () => {
    navigate(`/users/${userId}`);
  };

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">

      <HeaderTitleBar
        title={`Edit User: ${formData?.first_name} ${formData?.last_name}`}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`User "${formData?.first_name} ${formData?.last_name}" Updated`}
        success={success}
        error={error}
      />

      {(loading || userData === null) ? (
        <div>Loading...</div>
      ) : error ? (
        <div className="text-red-500">Error loading user details.</div>
      ) : (

        <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/2">
          <div className="mt-6 w-full">
            <div className="grid grid-cols-2 text-sm">
              <div className='flex gap-2 '>
                <p className="font-semibold">MSO</p>
                <p>{userData?.mso?.name || 'N/A'}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className='flex gap-2 '>
                <p className="font-semibold">Company</p>
                <p>{userData?.company?.name || 'N/A'}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div className='flex gap-2 '>
                <p className="font-semibold">Area</p>
                <p>{userData?.area?.name || 'N/A'}</p>
              </div>
            </div>
          </div>

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'First Name'}
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            placeholder="Enter First Name"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Last Name'}
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            placeholder="Enter Last Name"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-2 gap-8">
          <FormTextInput
            label={'Email'}
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter Email"
            formErrors={formErrors}
          />

          <FormTextInput
            label={'Phone Number'}
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            placeholder="Enter Phone Number"
            formErrors={formErrors}
          />
        </div>

        <FormTextInput
          label={'Address 1'}
          name="address_1"
          value={formData.address_1}
          onChange={handleChange}
          placeholder="Enter Address"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Address (continued)'}
          name="address_2"
          value={formData.address_2}
          onChange={handleChange}
          placeholder="Enter Address (continued)"
          formErrors={formErrors}
        />

        <div className="grid justift-items-start grid-cols-3 gap-8">
          <FormTextInput
            label={'City'}
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="Enter City"
            formErrors={formErrors}
          />

          <FormStatesSelectBox
            state_id={formData.state_id}
            required={false}
            formErrors={formErrors}
            onChange={handleChange}
          />

          <FormTextInput
            label={'Zip'}
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            placeholder="Enter Zip Code"
            formErrors={formErrors}
          />
        </div>

        <div className="grid justift-items-start grid-cols-3 gap-8">
          <FormSelectBox
            label={'User Type'}
            name="user_type"
            defaultValue={formData.user_type}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a User Type'}
            onChange={handleChange}
            formErrors={formErrors}
            data={user_types}
          />

          <FormSelectBox
            label={'Role'}
            name="role"
            defaultValue={formData.role}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Role'}
            onChange={handleChange}
            formErrors={formErrors}
            data={roles}
          />

          <FormSelectBox
            label={'Status'}
            name="status"
            defaultValue={formData.status}
            valueField={'id'}
            keyField={'name'}
            zeroOption={'Select a Status'}
            onChange={handleChange}
            formErrors={formErrors}
            data={statuses}
          />
        </div>
        <div className="grid justift-items-start grid-cols-2 gap-8 pt-10">
          <FormPasswordInput
            label={'Password'}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter a Password"
            formErrors={formErrors}
          />

          <FormPasswordInput
            label={'Password Confirmation'}
            name="password_confirmation"
            value={formData.password_confirmation}
            onChange={handleChange}
            placeholder="Confirm your Password"
            formErrors={formErrors}
          />
        </div>
        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Update User
          </button>
        </div>
      </form>

      )}
    </div>
  );
};

export default EditUser;
