import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import HeaderTitleBar from 'components/HeaderTitleBar';
import { addMso } from 'features/msos/msosSlice';
import FormTextInput from 'components/formHepers/FormTextInput';
import FormSelectBox from 'components/formHepers/FormSelectBox';
import FormStatesSelectBox from 'components/formHepers/FormStatesSelectBox';
import FormSubmissionResult from 'components/formHepers/FormSubmissionResult';

import { fetchMsos } from 'features/msos/msosSlice';

import 'tailwindcss/tailwind.css';
import { addCompany } from 'features/companies/companiesSlice';

const CreateCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    mso_id: 0,
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    zip: '',
    state_id: 0
  });

  const { data: msosData } = useSelector((state) => state.msos);
  const { success, error } = useSelector((state) => state.companies);
  const [formErrors, setFormErrors] = useState({});

  const fetchMsosData = useCallback(() => {
    dispatch(fetchMsos({ page: 1, per: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    fetchMsosData();
  }, [fetchMsosData]);

  const validateForm = () => {
    const newErrors = {};

    if (!parseInt(formData.mso_id) > 0) {
      newErrors.mso_id = 'MSO is required';
    }

    if (!formData.name) {
      newErrors.name = 'Company Name is required';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const newData = formData;
    newData[name] = value;

    setFormData(newData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors({});
    if (validateForm()) {
      dispatch(addCompany({ formData, navigate }));
    }
  };

  const handleBack = () => {
    navigate('/companies')
  }

  return (
    <div className="p-4 mb-6 bg-white shadow rounded-lg">
      <HeaderTitleBar
        title={'Create Company'}
        showBtn={true}
        btnTitle={'Back'}
        btnClick={handleBack}
      />

      <FormSubmissionResult
        successText={`Company "${formData.name}" Created`}
        success={success}
        error={error}
      />

      <form onSubmit={handleSubmit} className="space-y-4 mt-6 w-1/4">

        <FormSelectBox
          label={'MSO'}
          name="mso_id"
          defaultValue={formData.mso_id}
          valueField={'id'}
          keyField={'name'}
          zeroOption={'Select a MSO'}
          onChange={handleChange}
          formErrors={formErrors}
          data={msosData}
        />

        <FormTextInput
          label={'Company Name'}
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Enter Company Name"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Phone Number'}
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          placeholder="Enter a Phone Number"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Address 1'}
          name="address_1"
          value={formData.address_1}
          onChange={handleChange}
          placeholder="Enter an Address"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'Address (continued)'}
          name="address_2"
          value={formData.address_2}
          onChange={handleChange}
          placeholder="Enter an Address (continued)"
          formErrors={formErrors}
        />

        <FormTextInput
          label={'City'}
          name="city"
          value={formData.city}
          onChange={handleChange}
          placeholder="Enter a City"
          formErrors={formErrors}
        />

        <FormStatesSelectBox
          state_id={formData.state?.id || 0}
          required={false}
          formErrors={formErrors}
          onChange={handleChange}
        />

        <FormTextInput
          label={'Zip'}
          name="zip"
          value={formData.city}
          onChange={handleChange}
          placeholder="Enter a Zip Code"
          formErrors={formErrors}
        />

        <div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Company
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCompany;